<!-- 用水监测台账 -->
<template>
  <section>
    <section class="search-container">
      <a-form layout="inline" class="m-t-14">
        <div class="search-div-left formItem">
          <a-form-item label="设备名称">
            <a-input v-model="searchInfo.deviceName" placeholder="请输入"></a-input>
          </a-form-item>

          <a-form-item label="更新时间">
            <a-range-picker
              allowClear
              v-model="priceRangeDate"
              @calendarChange="calendarPriceRangeChange"
              @change="changePriceRangeDate"
            />
             <!--:disabled-date="disabledPriceRangeDate" 
               format="YYYY-MM-DD HH:mm:ss"
              show-time -->
          </a-form-item>
          <a-form-item>
            <a-button type="primary" class="m-r-8" @click="search">
              <a-icon type="search" />查询
            </a-button>
            <a-button @click="reset">
              <a-icon type="reload" />重置
            </a-button>
          </a-form-item>
        </div>
        <!-- <div class="search-div-right">
          <a-form-item style="float:right;margin-right:0;">
            <a-button type="primary" class="m-r-8" @click="search">
              <a-icon type="search" />搜索
            </a-button>
            <a-button @click="reset">
              <a-icon type="reload" />重置
            </a-button>
          </a-form-item>
        </div> -->
      </a-form>
    </section>

    <section class="list-table-container m-t-18">
      <a-table 
        :bordered="false"
        class="m-t-18" :columns="columns" :data-source="listData" :pagination="false" :locale="locale">
        <span slot="day" slot-scope="text">{{text}}</span>
        <span slot="electricity" slot-scope="text">{{text}}·t</span>
      </a-table>
    </section>
    <div class="content-page pagination-container" v-if="total">
        <a-pagination
          :showQuickJumper="true"
          :showSizeChanger="true"
          :current="pageNum"
          :pageSize="pageSize"
          :total="total"
          :show-total="(total) => `共${total}条`"
          @change="pageNumChange"
          @showSizeChange="pageSizeChange"
        />
      </div>
  </section>
</template>

<script>
// import empty from "@/components/empty";
import moment from 'moment'
// import uploadFile from '@/components/uploadFile'
const columns = [
  { title: '序号', dataIndex: 'key', align: 'center', key: 'key', width: 80 },
  { title: '设备名称', dataIndex: 'deviceName', align: 'center', key: 'deviceName', ellipsis: true },
  { title: '更新时间', dataIndex: 'statisticsTime', align: 'center', key: 'statisticsTime', scopedSlots: { customRender: "day" } },
  { title: '用水量', dataIndex: 'dataValue', align: 'center', key: 'dataValue',scopedSlots: { customRender: "electricity" } },

]
export default {
  data() {
    return {
      columns,//table头
      // locale: {
      //   emptyText: <empty></empty>
      // },
      //分页--------
      total: 0,
      pageSize: 10,
      pageNum: 1,
      //------------
      listData: [],//table信息
      searchInfo: {},//搜索信息
      priceRangeDate: [
        // moment(this.formatDate(new Date().getTime() - 14 * 86400000, 'Y-m-d')),
        // moment(this.formatDate(new Date().getTime(), 'Y-m-d'))
      ],
      selectPriceDate: '',
      offsetDays: 1209600 * 1000 //最多选择范围31天ms
    }
  },
  created() {
    this.init()
  },
  computed: {
    // isSet() {
    //   return this.$route.query.date?this.$route.query.date:null;
    // },
  },
  mounted() {
    // if(this.$route.query.date){
    //   this.priceRangeDate = [
    //     moment(this.formatDate(this.$route.query.date, 'Y-m-d')),
    //     moment(this.formatDate(parseInt(this.$route.query.date) + 1 * 86400, 'Y-m-d'))
    //   ]
    // }
  },
  methods: {
    //初始化
    init() {
      this.getDeviceElectricityData();//获取用电监测列表
    },
    //选择完时间
    changePriceRangeDate() {
      this.selectPriceDate = ''
    },
    //选择开始时间/结束时间
    calendarPriceRangeChange(date) {
      this.selectPriceDate = date[0]
    },
    //根据选择的开始时间/结束时间，动态渲染要禁用的日期
    disabledPriceRangeDate(current) {
      if (this.selectPriceDate) {
        let selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf()
        return current > moment(this.formatDate(new Date(selectV + this.offsetDays).getTime(), 'Y-m-d')) ||
          current < moment(this.formatDate(new Date(selectV - this.offsetDays).getTime(), 'Y-m-d')) ||
          current > moment().endOf('day')
      } else {
        return current > moment().endOf('day')
      }
    },
    formatDate: (timestamp, formatLayout = 'Y-m-d H:i:s') => {
      let formatDate = ""
      formatLayout = formatLayout.toUpperCase()
      timestamp = (timestamp + "").length > 11 ? timestamp : timestamp * 1000
      let time = new Date(timestamp)
      for (let i in formatLayout) {
        if (['Y', 'M', 'D', 'W', 'H', 'I', 'S'].indexOf(formatLayout[i]) >= 0) {
          switch (formatLayout[i]) {
            case 'Y':
              formatDate += time.getFullYear()
              break;
            case 'M':
              formatDate += time.getMonth() >= 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
              break;
            case 'D':
              formatDate += time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
              break;
            case 'W':
              formatDate += time.getDay() == 0 ? 7 : time.getDay()
              break;
            case 'H':
              formatDate += time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
              break;
            case 'I':
              formatDate += time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
              break;
            case 'S':
              formatDate += time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
              break;
          }
        } else {
          formatDate += formatLayout[i]
        }
      }

      return formatDate
    },
    //用电监测-列表
    getDeviceElectricityData() {
      this.listData = [];
      let params = {
        deviceName: this.searchInfo.deviceName ? this.searchInfo.deviceName : '',
        current: this.pageNum,
        size: this.pageSize
      }
      if (params.deviceName == '') delete params.deviceName
      if ((this.priceRangeDate && this.priceRangeDate.length)||this.isSet!=null) {
        if(this.priceRangeDate && this.priceRangeDate.length){

          params.startTime = this.priceRangeDate[0].format("YYYY-MM-DD");
          params.endTime = this.priceRangeDate[1].format("YYYY-MM-DD");
        }else{
          params.startTime = moment(this.formatDate(this.$route.query.date, 'Y-m-d')).format("YYYY-MM-DD");
          params.endTime = moment(this.formatDate(parseInt(this.$route.query.date) + 1 * 86400, 'Y-m-d')).format("YYYY-MM-DD");
        }
      }
      console.log('params====>', params);
      this.$api.getDeviceElectricityData2(params).then(res => {
        console.log('用电监测：', res);
        if (res.code == 200) {
          this.total = res.data.total;
          if (res.data.records && res.data.records.length) {
            this.listData = res.data.records;
            this.listData.forEach((element, index) => {
              // element.key = index + 1;
              element.key = (this.pageNum - 1) * this.pageSize + (index + 1);
            });
          }
        }
      })
    },

    //重置
    reset() {
      this.searchInfo = {}
      this.pageNum = 1;
      this.pageSize = 10;
      this.priceRangeDate = [
        // moment(this.formatDate(new Date().getTime() - 14 * 86400000, 'Y-m-d')),
        // moment(this.formatDate(new Date().getTime(), 'Y-m-d'))
      ]
      this.getDeviceElectricityData();
    },
    //搜索
    search() {
      this.pageNum = 1;
      this.size = 10;
      console.log(this.searchInfo);
      this.getDeviceElectricityData();
    },
    //分页
    changePageNum(p) {
      this.pageNum = p.current
    },
    pageNumChange(current) {
      this.pageNum = current;
      this.getDeviceElectricityData();
    },
    pageSizeChange(current, size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.getDeviceElectricityData();
    },
  }
}
</script>
<style lang="scss" scoped>
.addPersonImg {
  height: 143px;
  width: 143px;
  border: #dddddd 1px dashed;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 0;
  img {
    // width: 142px;
    height: 41px;
  }
}
.gray {
  color: gray;
}
.point {
  cursor: pointer;
}

// 必填星号
.redStar {
  position: relative;
  box-sizing: border-box;
  padding-right: 10px;

  &:after {
    display: block;
    content: "*";
    position: absolute;
    width: 14px;
    height: 14px;
    color: #f5222d;
    font-size: 20px;
    top: -7px;
    left: -15px;
  }
}

.clickOK {
  z-index: 200;
  position: absolute;
  top: 0;
  display: inline-block;
  height: 100%;

  height: 106px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 30px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: #ff9933;
  user-select: none;
}
.m-l-10 {
  margin-left: 10px;
}
.quanzhong {
  z-index: 9999;
}
</style>